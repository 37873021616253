import { render, staticRenderFns } from "./ActionsCellRenderer.vue?vue&type=template&id=6bcbc50e&"
import script from "./ActionsCellRenderer.vue?vue&type=script&lang=js&"
export * from "./ActionsCellRenderer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4073529217/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6bcbc50e')) {
      api.createRecord('6bcbc50e', component.options)
    } else {
      api.reload('6bcbc50e', component.options)
    }
    module.hot.accept("./ActionsCellRenderer.vue?vue&type=template&id=6bcbc50e&", function () {
      api.rerender('6bcbc50e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project-archive/page/grid/ActionsCellRenderer.vue"
export default component.exports